import { AiOutlineDisconnect, AiOutlineStar } from "react-icons/ai"
import { BiEraser, BiImageAdd, BiReset } from "react-icons/bi"
import { CgSpinner } from "react-icons/cg"
import {
  FaBuilding,
  FaCanadianMapleLeaf,
  FaChevronDown,
  FaImages,
  FaInstagram,
  FaPenNib,
  FaPlus,
  FaRegMinusSquare,
  FaRegPlusSquare,
  FaSearch,
  FaShapes,
  FaStore,
  FaTag,
  FaTwitter,
  FaUserCircle,
} from "react-icons/fa"
import { FcRemoveImage } from "react-icons/fc"
import { FiChevronDown, FiChevronsUp } from "react-icons/fi"
import { GoLaw } from "react-icons/go"
import { HiSortAscending, HiSortDescending } from "react-icons/hi"
import { IoIosRocket } from "react-icons/io"
import {
  MdAutoFixHigh,
  MdClose,
  MdDelete,
  MdFormatBold,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatQuote,
  MdFormatUnderlined,
  MdImage,
  MdLaunch,
  MdLink,
  MdLinkOff,
  MdLogout,
  MdLooks3,
  MdLooksTwo,
  MdWeb,
} from "react-icons/md"

export const ArticleIcon = FaPenNib

export const BrandIcon = FaBuilding

export const StockistIcon = FaStore

export const CategoryIcon = FaShapes

export const ImageIcon = FaImages

export const AddImageIcon = BiImageAdd

export const DisconnectIcon = AiOutlineDisconnect

export const RemoveImage = FcRemoveImage

export const SearchIcon = FaSearch

export const TagIcon = FaTag

export const NortherLaunchIcon = MdLaunch

export const ExternalLaunchIcon = IoIosRocket

export const DropdownChevronIcon = FaChevronDown

export const RatingIcon = AiOutlineStar

export const ResetIcon = BiReset

export const EraseIcon = BiEraser

export const ChevronUpIcon = FiChevronsUp

export const ChevronDownIcon = FiChevronDown

export const LogoutIcon = MdLogout

export const AutofixIcon = MdAutoFixHigh

export const CloseIcon = MdClose

export const MapleLeafIcon = FaCanadianMapleLeaf

export const PlusIcon = FaPlus

export const DeleteIcon = MdDelete

export const PrivacyPolicyIcon = GoLaw

export const TermsOfUseIcon = GoLaw

export const AboutUsIcon = MdWeb

export const ExpandTreeIcon = FaRegPlusSquare

export const CollapseTreeIcon = FaRegMinusSquare

export const UserIcon = FaUserCircle

export const SortDescendingIcon = HiSortDescending

export const SortAscendingIcon = HiSortAscending

export const SocialMediaIcons = {
  Instagram: FaInstagram,
  Twitter: FaTwitter,
}

export const StructuredTextIcons = {
  BlockQuote: MdFormatQuote,
  Bold: MdFormatBold,
  Heading2: MdLooksTwo,
  Heading3: MdLooks3,
  Image: MdImage,
  Italic: MdFormatItalic,
  Link: MdLink,
  OrderedList: MdFormatListNumbered,
  Underlined: MdFormatUnderlined,
  Unlink: MdLinkOff,
  UnorderedList: MdFormatListBulleted,
}

export const SpinnerIcon = CgSpinner
