import { getOrFalse } from "app/common/fp-ts/either"
import { either } from "fp-ts"
import { pipe } from "fp-ts/function"
import * as t from "io-ts"

const production = "production"

const development = "development"

const NodeEnv = t.keyof({ [development]: null, [production]: null })

export const isProduction = pipe(
  pipe(
    NodeEnv.decode(process.env.NEXT_PUBLIC_NODE_ENV),
    either.alt(() => NodeEnv.decode(process.env.NODE_ENV)),
    getOrFalse((env) => env === production)
  )
)
