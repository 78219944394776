import { Head } from "blitz"
import React from "react"

interface GoogleAnalyticsProps {
  apiKey: string | undefined
}

export const GooglePlacesHead = ({ apiKey }: GoogleAnalyticsProps) => (
  <Head>
    <script
      async
      defer
      src={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`}
    ></script>
  </Head>
)

// export const GoogleMapsHead = ({ apiKey }: GoogleAnalyticsProps) => (
//   <Head>
//     <script
//       async
//       defer
//       src={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap&libraries=places`}
//     ></script>
//   </Head>
// )
