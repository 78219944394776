import { PrismaClient } from "@prisma/client"
import { enhancePrisma } from "blitz"

export const EnhancedPrisma = enhancePrisma(PrismaClient)

export * from "@prisma/client"

const prisma = new EnhancedPrisma({
  // log: ["query", "info", "warn", "error"],
})

export default prisma
