import { formatErrors } from "app/common/schema/formatErrors"
import { either, readerTaskEither, taskEither } from "fp-ts"
import { flow, pipe } from "fp-ts/function"
import * as t from "io-ts"

export const decodeWith =
  <A, O = A, I = unknown>(decoder: t.Type<A, O, I>) =>
  (i: I) =>
    pipe(i, decoder.decode, either.mapLeft(formatErrors))

export const decodeWithTE = <A, O = A, I = unknown>(decoder: t.Type<A, O, I>) =>
  flow(decodeWith(decoder), taskEither.fromEither)

export const decodeWithRTE = <A, O = A, I = unknown>(decoder: t.Type<A, O, I>) =>
  flow(decodeWith(decoder), readerTaskEither.fromEither)
