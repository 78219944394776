import Modal from "app/common/components/Modal"
import { useBooleanState } from "app/hooks/useBooleanState"
import { array } from "fp-ts"
import React, { createContext, PropsWithChildren, ReactElement, useContext, useState } from "react"

interface ModalContextOptions {
  hide: () => void
  modalContent: ReactElement[]
  show: (content: ReactElement) => void
}

const ModalContext = createContext<ModalContextOptions | undefined>(undefined)

// eslint-disable-next-line @typescript-eslint/ban-types
function ModalProvider({ children }: PropsWithChildren<{}>) {
  const [isShown, setIsShown] = useBooleanState(false)

  const [modalContent, setModalContent] = useState<ReactElement[]>([])

  const value = {
    hide: () => {
      setModalContent((prev) => {
        prev.pop()
        if (array.isEmpty(prev)) {
          setIsShown.false()
        }

        return [...prev]
      })
    },
    modalContent,
    show: (content: ReactElement) => {
      setIsShown.true()
      setModalContent((prev) => [...prev, content])
    },
  }

  return (
    <ModalContext.Provider value={value}>
      {isShown && <Modal />}
      {children}
    </ModalContext.Provider>
  )
}

const useModal = () => {
  const context = useContext(ModalContext)
  if (context === undefined) {
    throw new Error("useModal must be used within a a ModalProvider")
  }

  return context
}

export { ModalProvider, useModal }
