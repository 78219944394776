import { Menu } from "@headlessui/react"
import logout from "app/auth/mutations/logout"
import { GooglePlacesHead } from "app/common/components/GoogleMapsHead"
import { InstagramEmbedScript } from "app/common/components/InstagramEmbedScript"
import { ToastProvider } from "app/common/components/ToastProvider"
import { TwitterEmbedScript } from "app/common/components/TwitterEmbedScript"
import { decodeWith } from "app/common/core/decode"
import { getOrUndefined } from "app/common/fp-ts/either"
import { useCurrentUser } from "app/common/hooks/useCurrentUser"
import { logException } from "app/common/logging"
import { ModalProvider } from "app/core/components/modalContext"
import { CmsRouteMgr, UrlMgr } from "app/core/routes"
import {
  AboutUsIcon,
  ArticleIcon,
  BrandIcon,
  CategoryIcon,
  ImageIcon,
  LogoutIcon,
  MapleLeafIcon,
  NortherLaunchIcon,
  PrivacyPolicyIcon,
  SearchIcon,
  StockistIcon,
  TagIcon,
  TermsOfUseIcon,
  UserIcon,
} from "app/icons"
import { Head, Link, RouteUrlObject, useMutation, useRouter } from "blitz"
import { either, string } from "fp-ts"
import { pipe } from "fp-ts/function"
import * as t from "io-ts"
import React, { forwardRef, ReactNode } from "react"
import { IconType } from "react-icons"
import { apply, tw } from "twind"

const btn = apply`inline-block text-white font-semibold`

function UserMenuDropdown() {
  const currentUser = useCurrentUser()

  const [logoutMutation] = useMutation(logout)

  return (
    <Menu>
      <Menu.Button className={tw(`grid place-items-center`)}>
        <UserIcon className={tw(`inline`, `align-sub`)} />
      </Menu.Button>
      <Menu.Items
        className={tw(
          `absolute top-full  right-0`,
          `z-20`,
          `bg-white`,
          `flex(& col)`,
          `text-base text-left`,
          `border`,
          `mt-5 -mr-2`,
          `w-min`,
          `shadow-xl`
        )}
      >
        <Menu.Item>
          {() => (
            <div className={tw(`text-gray-600`, `py-4 px-8`, `whitespace-nowrap`)}>
              {currentUser?.name}
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <a
              className={tw(
                btn,
                `text(gray-600 left)`,
                `py-4 px-6`,
                `whitespace-nowrap hover:bg-gray-100`,
                active && "bg-gray-100"
              )}
              href={UrlMgr.Home()}
              rel="noopener noreferrer"
            >
              <NortherLaunchIcon className={tw(`inline`, `mr-3`, `text-2xl`)} />
              Go to Norther
            </a>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <button
              className={tw(
                btn,
                `text(gray-600 left)`,
                `py-4 px-6`,
                `whitespace-nowrap hover:bg-gray-100`,
                active && "bg-gray-100"
              )}
              onClick={async () => {
                await logoutMutation()
              }}
            >
              <LogoutIcon className={tw(`inline`, `mr-3`, `text-2xl`)} />
              Logout
            </button>
          )}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  )
}

const sectionHighlight = apply`font-semibold border(white) bg-blue-50 text-blue-900 sm:(rounded-none bg-gradient-to-r from-gray-100 to-gray-200)`

interface MenuListItemProps {
  IconComponent: IconType
  className?: string
  routeUrl: RouteUrlObject | string
  text: string
}

const MenuListItem = forwardRef<HTMLLIElement, MenuListItemProps>(
  ({ IconComponent, className, routeUrl, text }: MenuListItemProps, ref) => {
    const router = useRouter()

    return (
      <li
        className={tw(
          className,
          router.pathname.startsWith(string.isString(routeUrl) ? routeUrl : routeUrl.pathname) &&
            sectionHighlight
        )}
        ref={ref}
      >
        <Link href={routeUrl}>
          <a className={tw(`px-4 py-3`, `flex(& col sm:row) items-center`, `whitespace-nowrap`)}>
            <IconComponent className={tw(`sm:(inline mr-2)`)} />
            {text}
          </a>
        </Link>
      </li>
    )
  }
)

type LayoutProps = {
  children: ReactNode
  title?: string
}

const GoogleApiConfig = t.string

const Cms = ({ children, title }: LayoutProps) => {
  return (
    <>
      <Head>
        <title>{title ?? "Norther"}</title>
        <link href="/favicon.svg" rel="icon" />
      </Head>
      <TwitterEmbedScript />
      <InstagramEmbedScript />
      {pipe(
        process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
        decodeWith(GoogleApiConfig),
        either.mapLeft(logException),
        getOrUndefined((NEXT_PUBLIC_GOOGLE_API_KEY) => (
          <GooglePlacesHead apiKey={NEXT_PUBLIC_GOOGLE_API_KEY} />
        ))
      )}
      <ToastProvider>
        <ModalProvider>
          <div className={tw(`flex(& col)`, `min-h-screen`)}>
            <header
              className={tw(
                `flex items-center gap-5`,
                `shadow-md`,
                `bg-blue-900`,
                `text-white`,
                `px-4`,
                `select-none`,
                `relative`
              )}
            >
              <Link href={CmsRouteMgr.CmsDashboard()}>
                <a
                  className={tw(
                    `text(white xl) font-bold`,
                    `block`,
                    `py-4`,
                    `select-none`,
                    `mr-auto`
                  )}
                >
                  <MapleLeafIcon className={tw(`inline align-sub`, `mr-2`, `text-2xl`)} />
                  NortherCMS
                </a>
              </Link>
              <ul className={tw(`flex gap-5 items-center`)}>
                <li className={tw(`text-2xl relative`)}>
                  <UserMenuDropdown />
                </li>
              </ul>
            </header>
            <div className={tw(`flex(& 1)`)}>
              <aside
                className={tw(
                  `fixed bottom-0 left-0 right-0 sm:(static)`,
                  `z(10 sm:auto)`,
                  `bg(gray-500 sm:gray-100)`,
                  `flex sm:block`,
                  `py(0 sm:4)`,
                  `text(white xs sm:(gray-900 lg))`
                )}
              >
                <ul
                  className={tw(
                    `flex(& row sm:col) justify(between sm:(start)) items(center sm:stretch)`,
                    `overflow-x-auto`,
                    `select-none`,
                    `w(full sm:auto)`
                  )}
                >
                  <li
                    className={tw(
                      `px-4`,
                      `uppercase font-semibold text(base gray-400) tracking-wider `,
                      `hidden sm:block`
                    )}
                  >
                    Content
                  </li>

                  <MenuListItem
                    IconComponent={ArticleIcon}
                    routeUrl={CmsRouteMgr.CmsArticleListPage()}
                    text="Articles"
                  />
                  <MenuListItem
                    IconComponent={BrandIcon}
                    routeUrl={CmsRouteMgr.CmsBrandListPage()}
                    text="Brands"
                  />
                  <MenuListItem
                    IconComponent={StockistIcon}
                    routeUrl={CmsRouteMgr.CmsStockistListPage()}
                    text="Stockists"
                  />
                  <MenuListItem
                    IconComponent={CategoryIcon}
                    routeUrl={CmsRouteMgr.CmsCategoryListPage()}
                    text="Categories"
                  />
                  <MenuListItem
                    IconComponent={TagIcon}
                    routeUrl={CmsRouteMgr.CmsTagListPage()}
                    text="Tags"
                  />

                  <li
                    className={tw(
                      `px-4`,
                      `mt-8`,
                      `uppercase font-semibold text(base gray-400) tracking-wider`,
                      `hidden sm:block`
                    )}
                  >
                    Pages
                  </li>

                  <MenuListItem
                    IconComponent={AboutUsIcon}
                    routeUrl={CmsRouteMgr.CmsAboutUs()}
                    text="About Us"
                  />
                  <MenuListItem
                    IconComponent={PrivacyPolicyIcon}
                    routeUrl={CmsRouteMgr.CmsPrivacyPolicyPage()}
                    text="Privacy Policy"
                  />
                  <MenuListItem
                    IconComponent={TermsOfUseIcon}
                    routeUrl={CmsRouteMgr.CmsTermsOfUsePage()}
                    text="Terms of Use"
                  />

                  <li
                    className={tw(
                      `px-4`,
                      `mt-8`,
                      `uppercase font-semibold text(base gray-400) tracking-wider`,
                      `hidden sm:block`
                    )}
                  >
                    Media
                  </li>

                  <MenuListItem
                    IconComponent={ImageIcon}
                    routeUrl={CmsRouteMgr.CmsMediaListPage()}
                    text="Images"
                  />

                  <li
                    className={tw(
                      `px-4`,
                      `mt-8`,
                      `uppercase font-semibold text(base gray-400) tracking-wider`,
                      `hidden sm:block`
                    )}
                  >
                    Config
                  </li>

                  <MenuListItem
                    IconComponent={SearchIcon}
                    routeUrl={CmsRouteMgr.CmsSearchPage()}
                    text="Indexes"
                  />
                </ul>
              </aside>
              <main className={tw(`px(2 sm:6) py-6`, `flex-1`, `mb(24 sm:0)`)}>{children}</main>
            </div>
          </div>
        </ModalProvider>
      </ToastProvider>
      <div id="modal-root" />
    </>
  )
}

export default Cms
