import { CloseIcon } from "app/icons"
import { array } from "fp-ts"
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { apply, tw } from "twind"

interface ToastContextOptions {
  failure: (str: string) => void
  remove: (id: number) => void
  success: (str: string) => void
}

const ThemeContext = createContext<ToastContextOptions | null>(null)

type ToastType = "success" | "failure"

interface Toast {
  id: number
  str: string
  type: ToastType
}

interface ToastProps {
  toast: Toast
}

interface ToastListProps {
  toasts: Toast[]
}

export const useToast = () => useContext(ThemeContext) as ToastContextOptions

const getStyles = (type: ToastType) => {
  switch (type) {
    case "success":
      return apply`bg-green-100 text-green-700`

    case "failure":
      return apply`bg-red-100 text-red-700`
  }
}

const Toast: FC<ToastProps> = ({ toast }) => {
  const { remove } = useToast()
  useEffect(() => {
    const interval = setInterval(() => remove(toast.id), 3000)

    return () => clearInterval(interval)
  }, [remove, toast.id])

  return (
    <div
      className={tw(`flex(& row(reverse))`, `px-6 py-3`, getStyles(toast.type), `bg-opacity-95`)}
    >
      <button
        className={tw(`self-start`, `float-right`, `text(2xl sm:lg hover:red-600) font-bold`)}
        onClick={() => remove(toast.id)}
      >
        <CloseIcon />
      </button>
      <div className={tw(`my-auto flex-1`)}>{toast.str}</div>
    </div>
  )
}

const ToastList: FC<ToastListProps> = ({ toasts }) => (
  <div
    className={tw(
      `fixed top-0 right-0 left-0`,
      `flex(& col) gap-3`,
      `z-30`,
      array.isEmpty(toasts) && `hidden`
    )}
  >
    {toasts.map((toast) => (
      <Toast key={toast.id} toast={toast} />
    ))}
  </div>
)

export const ToastProvider: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  const i = useRef(0)

  const [toasts, setToasts] = useState<Toast[]>([])

  const success = useCallback((str: string) => {
    ++i.current
    setToasts((prev) => [...prev, { id: i.current, str, type: "success" }])
  }, [])

  const failure = useCallback((str: string) => {
    ++i.current
    setToasts((prev) => [...prev, { id: i.current, str, type: "failure" }])
  }, [])

  const remove = useCallback((id: number) => {
    setToasts((prev) => [...prev.filter((t) => t.id !== id)])
  }, [])

  const context: ToastContextOptions = {
    failure,
    remove,
    success,
  }

  return (
    <ThemeContext.Provider value={context}>
      {children}
      <ToastList toasts={toasts} />
    </ThemeContext.Provider>
  )
}
