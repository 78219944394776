import { constFalse, constTrue } from "fp-ts/function"
import { Dispatch, SetStateAction, useCallback, useState } from "react"

interface EnhancedDispatch extends Dispatch<SetStateAction<boolean | undefined>> {
  false: () => void
  toggle: () => void
  true: () => void
}

export const useBooleanState = (initalState?: boolean): [boolean, EnhancedDispatch] => {
  const [state, setState] = useState(initalState) as [boolean, EnhancedDispatch]

  setState.false = useCallback(() => setState(constFalse), [setState])
  setState.true = useCallback(() => setState(constTrue), [setState])
  setState.toggle = useCallback(() => setState((prev) => !prev), [setState])

  return [state, setState]
}
