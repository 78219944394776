import { useModal } from "app/core/components/modalContext"
import { CloseIcon } from "app/icons"
import React from "react"
import ReactDOM from "react-dom"
import { tw } from "twind"

const shadeStyles = tw(
  `fixed inset-0`,
  `overflow-auto`,
  `z-40`,
  `grid place-items-center`,
  `bg(black opacity-80)`,
  `p(0 sm:5)`
)

const cardStyles = tw(`bg-white`, `flex(& col) gap-5`, `sm:(inset-x-10 inset-y-auto)`, `p-5`)

const closeButtonStyles = tw(
  `self-end`,
  `text(blue-300 3xl hover:red-600) font-bold`,
  `grid place-items-center`
)

const contentContainerStyles = tw(`flex(& col 1) min-h-0`)

const Modal = () => {
  const { hide, modalContent } = useModal()

  return ReactDOM.createPortal(
    <div className={shadeStyles}>
      <div className={cardStyles}>
        <button className={closeButtonStyles} onClick={hide}>
          <CloseIcon />
        </button>
        <div className={contentContainerStyles}>{modalContent[modalContent.length - 1]}</div>
      </div>
    </div>,
    document.querySelector("#modal-root") as Element
  )
}

export default Modal
