import { LocaleD } from "@prisma/client"
import { readerTaskEither } from "fp-ts"

export type Unpromise<T> = T extends PromiseLike<infer U> ? U : T

export type Unarray<T> = T extends Array<infer U> ? U : T

export const CanadianLocales = {
  En: "en-ca",
  Fr: "fr-ca",
} as const

type CanadianLocale = ValueOf<typeof CanadianLocales>

interface Array<T> {
  filter<U extends T>(pred: (a: T) => a is U): U[]
}

export type ValueOf<T> = T[keyof T]

export type UnReaderTaskEither<T, R = unknown> = T extends readerTaskEither.ReaderTaskEither<
  R,
  Error,
  infer U
>
  ? U
  : T

export type Identifiable = { id: number }

export const selectId = <T extends Identifiable>(hasId: T) => hasId.id

export type Identifiables = Identifiable[]

export const localeToCanadianLocale = (locale: LocaleD): CanadianLocale =>
  locale === LocaleD.en_ca ? CanadianLocales.En : CanadianLocales.Fr
