import { isProduction } from "app/common/config/node"
import { CanadianLocales } from "app/common/utilities/types"
import { LocaleD } from "db"

export const CmsRouteMgr = {
  CmsAboutUs: () => `/about-us`,
  CmsArticleCreatePage: () => `/articles/create`,
  CmsArticleEditPage: ({ articleId }: { articleId: number }) =>
    `/${[`articles`, articleId].join("/")}`,
  CmsArticleListPage: () => `/articles`,
  CmsBrandCreatePage: () => `/brands/create`,
  CmsBrandEditPage: ({ brandId }: { brandId: number }) => `/${[`brands`, brandId].join("/")}`,
  CmsBrandListPage: () => `/brands`,
  CmsCategoryCreatePage: () => `/categories/create`,
  CmsCategoryEditPage: ({ categoryId }: { categoryId: number }) =>
    `/${[`categories`, categoryId].join("/")}`,
  CmsCategoryListPage: () => `/categories`,
  CmsDashboard: () => `/`,
  CmsMediaListPage: () => `/media`,
  CmsPrivacyPolicyPage: () => `/privacy-policy`,
  CmsSearchPage: () => `/search`,
  CmsStockistCreatePage: () => `/stockists/create`,
  CmsStockistEditPage: ({ stockistId }: { stockistId: number }) =>
    `/${[`stockists`, stockistId].join("/")}`,
  CmsStockistListPage: () => `/stockists`,
  CmsTagCreatePage: () => `/tags/create`,
  CmsTagEditPage: ({ tagId }: { tagId: number }) => `/${[`tags`, tagId].join("/")}`,
  CmsTagListPage: () => `/tags`,
  CmsTermsOfUsePage: () => `/terms-of-use`,
}

const routePrefix = (locale?: string) => (locale === LocaleD.fr_ca ? `/${CanadianLocales.Fr}` : "")

type LocaleOnlyArgs = { locale?: LocaleD }

type AboutArgs = LocaleOnlyArgs

type ArticleArgs = { number?: number; slug: string } & LocaleOnlyArgs

type BrandArgs = { slug: string } & LocaleOnlyArgs

type CategoryArgs = { number?: number; slugPath?: string[] | undefined } & LocaleOnlyArgs

type ContactArgs = LocaleOnlyArgs

type HomeArgs = LocaleOnlyArgs

type PrivacyPolicyArgs = LocaleOnlyArgs

type TermsOfUseArgs = LocaleOnlyArgs

type SearchArgs = { q: string } & LocaleOnlyArgs

const RouteMgr = {
  About: ({ locale }: AboutArgs = {}) => `${routePrefix(locale)}/about`,

  ArticlePage: ({ locale, number, slug }: ArticleArgs) =>
    `${routePrefix(locale)}/${[`articles`, slug, ...(number && number > 1 ? [number] : [])].join(
      "/"
    )}`,

  BrandPage: ({ locale, slug }: BrandArgs) =>
    `${routePrefix(locale)}/${[`brands`, slug].join("/")}`,

  CategoryPage: ({ locale, number, slugPath }: CategoryArgs = {}) =>
    `${routePrefix(locale)}/${[
      `categories`,
      ...(slugPath ? slugPath : []),
      ...(number && number > 1 ? [number] : []),
    ].join("/")}`,

  Contact: ({ locale }: ContactArgs = {}) => `${routePrefix(locale)}/contact`,

  Home: ({ locale }: HomeArgs = {}) => `${routePrefix(locale)}`,

  PrivacyPolicy: ({ locale }: PrivacyPolicyArgs = {}) => `${routePrefix(locale)}/privacy-policy`,

  Search: ({ locale, q }: SearchArgs) => `${routePrefix(locale)}/search/${q}`,

  TermsOfUse: ({ locale }: TermsOfUseArgs = {}) => `${routePrefix(locale)}/terms-of-use`,
}

const baseUrl = isProduction ? "https://norther.ca" : "http://localhost:3000"

export const UrlMgr = {
  About: ({ locale }: AboutArgs = {}) => baseUrl.concat(RouteMgr.About({ locale })),

  ArticlePage: ({ locale, number, slug }: ArticleArgs) =>
    baseUrl.concat(RouteMgr.ArticlePage({ locale, number, slug })),

  BrandPage: ({ locale, slug }: BrandArgs) => baseUrl.concat(RouteMgr.BrandPage({ locale, slug })),

  CategoryPage: ({ locale, number, slugPath }: CategoryArgs = {}) =>
    baseUrl.concat(RouteMgr.CategoryPage({ locale, number, slugPath })),

  Contact: ({ locale }: ContactArgs = {}) => baseUrl.concat(RouteMgr.Contact({ locale })),

  Home: ({ locale }: HomeArgs = {}) => baseUrl.concat(RouteMgr.Home({ locale })),

  PrivacyPolicy: ({ locale }: PrivacyPolicyArgs = {}) =>
    baseUrl.concat(RouteMgr.PrivacyPolicy({ locale })),

  Search: ({ locale, q }: SearchArgs) => baseUrl.concat(RouteMgr.Search({ locale, q })),

  TermsOfUse: ({ locale }: TermsOfUseArgs = {}) => baseUrl.concat(RouteMgr.TermsOfUse({ locale })),
}
